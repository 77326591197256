import {
    Backdrop, Box,
    Divider, Grid, Typography
} from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useInterval from "@use-it/interval";
import { filter, find, flatMap, get, isEmpty, round, sumBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import Bag from "../../api-services/bag";
import { openAuthView } from "../../store/app/actions";
import { payCheck, resetCheckPayment } from "../../store/order/actions";
import reduceByCouponsChecked from "../../store/reduceByCouponsChecked";
import {
    getExternalGiftCards,
    setDefaultPaymentMethod
} from "../../store/user/actions";
import AnimatedCheckmark from "../animated-checkmark";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import CheckItems from "./check-items";
import PaymentForm from "./payment-form";

import PriceLine from "../PriceLine";
import ShareCheck from "./share-check";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import { ElementsConsumer } from "@stripe/react-stripe-js";

function Check({
  context,
  checkId,
  payForCheck,
  resetCheck,
  openAuthView,
  setDefaultPaymentMethod,
  order: { checkPaymentProcessing, checkPaymentError, lastCheckDetails },
  getExternalGiftCards,
  onClose,
}) {
  const {
    user,
    appStyles,
    T,
    pageContext: { business },
  } = context;
  const { classes } = useStyles();

  const { id: businessId, currencySymbol } = business;

  const [checkDetails, setCheckDetails] = useState();
  const [checkNotFound, setCheckNotFound] = useState();

  const checkCloseDate = get(checkDetails, "check.closedDate");

  const [
    shouldLoadExternalGiftCards,
    setShouldLoadExternalGiftCards,
  ] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [busy, setBusy] = useState();
  const [processingCheck, setProcessingCheck] = useState();
  const [
    doneMyPaymentWaitingForOthers,
    setDoneMyPaymentWaitingForOthers,
  ] = useState();

  const branchId = get(checkDetails, "check.branchId");
  useEffect(() => {
    if (branchId && shouldLoadExternalGiftCards) {
      setShouldLoadExternalGiftCards(false);
      getExternalGiftCards(branchId);
    }
  }, [branchId, shouldLoadExternalGiftCards]);

  const priceDetails = useMemo(() => {
    const chargeCardPriceDetails = get(checkDetails, "chargeCard");
    const currentCheckPriceDetails = {
      ...chargeCardPriceDetails,
      ...get(checkDetails, "check.currentPriceDetails", {}),
    };

    return currentCheckPriceDetails;
  }, [checkDetails]);

  useEffect(() => {
    console.log({ lastCheckDetails, checkDetails });
    if (
      lastCheckDetails &&
      checkDetails &&
      lastCheckDetails.check.id === checkDetails.check.id &&
      lastCheckDetails.check.lastUpdatedDate >
        checkDetails.check.lastUpdatedDate
    ) {
      setCheckDetails(lastCheckDetails);
      setProcessingCheck(false);
      setDoneMyPaymentWaitingForOthers(
        Boolean(
          find(get(lastCheckDetails, "check.checkPayments"), "paidBy.me"),
        ),
      );
    }
  }, [
    get(lastCheckDetails, "check.lastUpdatedDate"),
    lastCheckDetails,
    checkDetails,
  ]);

  const getCheck = useCallback(() => {
    if (!checkId || checkNotFound) {
      return;
    }
    if (fetching || updating) {
      return;
    }
    console.log(`[Check Poller]: polling check id: ${checkId}`);
    setFetching(true);

    const bag = new Bag(user);
    bag
      .getCheckDetails(checkId)
      .then((checkResponse) => {
        setFetching(false);
        if (checkResponse && !updating) {
          setCheckDetails(checkResponse);
          setCheckNotFound(false);
        }
      })
      .catch((error) => {
        setFetching(false);
      });
  }, [user, checkId, fetching, updating]);

  useEffect(getCheck, []); //called for first render

  useInterval(getCheck, pollingInterval);

  const handleUpdateCheck = useCallback(
    (checkItems) => {
      if (updating) {
        console.log("skipped update");
        return;
      }
      console.log(`[Check Poller]: updating check id: ${checkId}`);
      setUpdating(true);

      const bag = new Bag(user);
      bag
        .updateCheck({ checkItems, id: checkId })
        .then((checkDetailsUpdateResponse) => {
          if (checkDetailsUpdateResponse) {
            setCheckDetails(checkDetailsUpdateResponse);
          }
          setUpdating(false);
        })
        .catch((error) => {
          setUpdating(false);
        });
    },
    [user, fetching],
  );

  const debounceUpdateCheck = useDebouncedCallback(handleUpdateCheck, 350);

  const handleSubmit = useCallback(
    (paymentDetails) => {
      payForCheck({
        ...paymentDetails,
        checkDetails,
        batchIndex: -1, //cancel the ability to select coupons
      });
    },
    [checkDetails],
  );

  useEffect(() => {
    if (checkPaymentProcessing) {
      setProcessingCheck(true);
    }
  }, [checkPaymentProcessing]);

  const totalLockedForPaymentByMe = useMemo(
    () =>
      sumBy(
        filter(
          flatMap(get(checkDetails, "check.checkItems"), "lockedForPaymentBy"),
          "me",
        ),
        "lockCount",
      ),
    [get(checkDetails, "check.lastUpdatedDate", "")],
  );

  const errorMessage = !get(checkDetails, "check.readyForPayment")
    ? totalLockedForPaymentByMe === 0
      ? T("Your order is being placed, select your items for payment")
      : T("Your order is still being placed...")
    : priceDetails.total === 0 &&
      totalLockedForPaymentByMe === 0 &&
      T("Select Items to Pay");

  const amountDueForCheck = round(
    get(checkDetails, "check.finalPriceDetails.total") -
      sumBy(get(checkDetails, "check.checkPayments"), "amount"),
    3,
  );
  const [showPaymentSummary, setShowPaymentSummary] = useState(false);
  useEffect(() => {
    if (processingCheck) {
      setShowPaymentSummary(true);
    }
  }, [processingCheck]);

  return (
    <>
      <Backdrop
        open={checkNotFound && checkId && !fetching}
        className={classes.backdrop}
      >
        <Box sx={{ marginTop: 30, minWidth: 320 }}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <Typography>{T("Sorry check not found")}</Typography>
            </Grid>
            <Grid item>
              <Button appStyles={appStyles} onClick={onClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
      <Backdrop
        open={processingCheck || showPaymentSummary}
        className={classes.backdrop}
      >
        <Box sx={{ marginTop: 30, minWidth: 320 }}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <AnimatedCheckmark
                loading={processingCheck || !doneMyPaymentWaitingForOthers}
                checked={doneMyPaymentWaitingForOthers}
                appStyles={appStyles}
                color="white"
              />
            </Grid>

            {!processingCheck && doneMyPaymentWaitingForOthers ? (
              <Card appStyles={appStyles} style={{ padding: "20px" }}>
                <Grid item>
                  <Typography variant="h3" color="secondary">
                    {T("Thank You")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="column"
                  className={classes.paymentSummary}
                >
                  <Grid item>
                    <Typography align="center">
                      {T("Your payment was proccessed succesfully")}
                    </Typography>
                  </Grid>
                  {Boolean(amountDueForCheck) && (
                    <>
                      <Grid item>
                        <Divider className={classes.lightDivider} />
                      </Grid>
                      <Grid item className={classes.priceLineWrapper}>
                        <PriceLine
                          className={classes.priceline}
                          label={T("Current amount due:")}
                          value={amountDueForCheck}
                          currencySymbol={currencySymbol}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <AppContainer.Footer
                  appStyles={appStyles}
                  transparentGradient
                  center
                >
                  <AppContainer.Footer.Button
                    onClick={onClose}
                    appStyles={appStyles}
                  >
                    {T("Close")}
                  </AppContainer.Footer.Button>
                </AppContainer.Footer>
              </Card>
            ) : (
              <>
                <Grid item>
                  <Typography variant="caption" color="white">
                    {T("Processing Payment...")}
                  </Typography>
                </Grid>
                <Grid item />
              </>
            )}
          </Grid>
        </Box>
      </Backdrop>
      <Grid container direction="column">
        <ShareCheck context={context} checkDetails={checkDetails} />
      </Grid>
      <Card appStyles={appStyles}>
        <CheckItems
          loading={fetching}
          checkDetails={checkDetails}
          context={context}
          onChange={debounceUpdateCheck.callback}
        />
      </Card>
      {!checkCloseDate &&
      amountDueForCheck &&
      (context.paymentTypeDetails.paymentType === PAYMENT_ID.STRIPE ? (
        <StripeProvider
          options={{
            mode: "payment",
            amount: Math.floor(amountDueForCheck * 100),
            currency: context.pageContext.business.currency.toLowerCase(),
            paymentMethodCreation: "manual",
          }}
          paymentTypeDetails={context.paymentTypeDetails}
        >
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <PaymentForm
                {...{...context, checkDetails}}
                onSubmit={handleSubmit}
                onValidate={() => setBusy(true)}
                onValidationCompleted={() => setBusy(false)}
                openAuthView={openAuthView}
                priceDetails={priceDetails}
                setDefaultPaymentMethod={setDefaultPaymentMethod}
                errorMessage={errorMessage}
                {...{ stripe, elements }}
              />
            )}
          </ElementsConsumer>
        </StripeProvider>
      ) : (
        <PaymentForm
          {...{...context, checkDetails}}
          onSubmit={handleSubmit}
          onValidate={() => setBusy(true)}
          onValidationCompleted={() => setBusy(false)}
          openAuthView={openAuthView}
          priceDetails={priceDetails}
          setDefaultPaymentMethod={setDefaultPaymentMethod}
          errorMessage={errorMessage}
        />
      ))}
      {checkCloseDate && (
        <Card appStyles={appStyles}>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>
              <Box sx={{ marginTop: 3 }}>
                <CheckCircleIcon fontSize="large" />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h5">{T("Thank you!")}</Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                {T("Your payment was proccessed succesfully")}
              </Typography>
            </Grid>
          </Grid>
          <AppContainer.Footer
            appStyles={appStyles}
            transparentGradient={!appStyles.withoutFooterButtonGradient}
            center
          >
            <AppContainer.Footer.Button
              onClick={onClose}
              appStyles={appStyles}
            >
              {T("Close")}
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        </Card>
      )}
    </>
  );
}

const mapStateToProps = (state, props) => {
  const { order } = state;
  return { order, ...props };
};

const mapDispatchToProps = (dispatch, { context }) => {
  const {
    pageContext: { business },
  } = context;

  const currency = get(business, "openChargeCard.price.currency", 0);

  return {
    payForCheck: (params) =>
      dispatch(
        payCheck({
          ...params,
          currency,
        }),
      ),
    resetCheck: (params) => dispatch(resetCheckPayment()),
    setDefaultPaymentMethod: (token) =>
      dispatch(setDefaultPaymentMethod(token)),
    openAuthView: (params) => dispatch(openAuthView(params)),
    getExternalGiftCards: (params) => dispatch(getExternalGiftCards(params)),
  };
};

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    alignItems: "flex-start",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.85)",
    color: "#ccc",
  },
  lightDivider: {
    borderColor: "#ccc",
  },
  paymentSummary: {
    width: 320,
  },
  priceline: {
    fontSize: 14,
  },
  priceLineWrapper: {
    width: "100%",
  },
}));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Check);

const pollingInterval = 10000;
